<form [formGroup]="form" (ngSubmit)="placeOrder()">
  <div class="py-3">
    <div class="position-relative m-4">
      <div
        class="progress"
        role="progressbar"
        aria-label="Progress"
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="100"
        style="height: 1px"
      >
        <div class="progress-bar" style="width: 0%"></div>
      </div>
      <button
        type="button"
        class="position-absolute top-0 start-0 translate-middle btn btn-sm btn-primary rounded-pill"
        style="width: 2rem; height: 2rem"
      >
        1
      </button>
      <button
        type="button"
        class="position-absolute top-0 start-50 translate-middle btn btn-sm btn-secondary rounded-pill"
        style="width: 2rem; height: 2rem"
      >
        2
      </button>
      <button
        type="button"
        class="position-absolute top-0 start-100 translate-middle btn btn-sm btn-secondary rounded-pill"
        style="width: 2rem; height: 2rem"
      >
        3
      </button>
    </div>
  </div>
  <div class="card shadow rounded bg-body-tertiary">
    <div class="card-body">
      <h4>Choose package</h4>
      <hr />
      <div class="mb-3">
        <label for="package" class="form-label">Chooase Package</label>
        <div class="row">
          <div
            class="col-12 col-md-6 col-lg mb-3"
            *ngFor="let product of products; let i = index"
          >
            <div class="card bg-body-tertiary border-{{ productTheme[i] }}">
              <div class="card-header bg-{{ productTheme[i] }}">
                <div class="d-flex flex-row">
                  <p class="flex-grow-1">
                    <b>{{ product.Name }}</b>
                  </p>
                  <h4 [ngClass]="productIcons[i]"></h4>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <p class="col">Minimum</p>
                  <p class="col">
                    {{ product.MinimumAmount | currency : "$" }}
                  </p>
                </div>
                <div class="row">
                  <p class="col">Maximum</p>
                  <p class="col">
                    {{ product.MaximumAmount | currency : "$" }}
                  </p>
                </div>
                <div class="row">
                  <p class="col">Daily</p>
                  <p class="col">{{ product.DailyRate }} %</p>
                </div>
                <div class="row">
                  <div class="col text-center">
                    <h2
                      *ngIf="package.value == product.Id"
                      class="text-success bi bi-check-lg"
                    ></h2>
                  </div>
                </div>
                <hr />
                <div class="d-flex flex-row">
                  <div class="flex-grow-1">
                    <div class="row">
                      <button
                        class="btn btn-sm btn-{{ productTheme[i] }}"
                        type="button"
                        (click)="choosePackage(product)"
                      >
                        Choose Package
                        <i
                          *ngIf="package.value == product.Id"
                          class="float-end text-black bi bi-check-lg"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label for="amount" class="form-label">Amount</label>
        <div class="input-group">
          <span class="input-group-text" id="basic-addon1">$</span>
          <input
            class="form-control"
            type="tel"
            placeholder="Enter amount"
            min="100"
            formControlName="amount"
          />
        </div>
        <div class="text-danger" *ngIf="amount.touched && amount.errors">
          <p *ngIf="amount.errors['required']">Please enter amount.</p>
          <p *ngIf="amount.errors['min']">
            Minimum amount is {{ amount.errors["min"].min | currency : "$" }}
          </p>
          <p *ngIf="amount.errors['max']">
            Maximum amount is {{ amount.errors["max"].max | currency : "$" }}
          </p>
          <p *ngIf="amount.errors['invalidAmount']">
            Amount must be multiples of $50
          </p>
        </div>
      </div>
      <div class="mb-3">
        <button class="btn btn-primary pr-3" type="submit">Buy</button>
      </div>
    </div>
  </div>
</form>
